<template>
<div>
    <div @click="show = true">
        <template v-if="info && info.nickname">
            <a-tooltip>
                <template #title>
                    <div>客户等级：{{info.level.level}}</div>
                    <div>客户地址：{{info.address}}</div>
                    <div>点击可重新选择</div>
                </template>
                <span class="pt">{{ info.nickname }}</span>
            </a-tooltip>
        </template>
        <template v-else>
            <span class="pt">选择客户</span>
        </template>
    </div>


    <a-modal title="选择客户" width="900px" v-model:visible="show" :footer="null">
        <a-input-search
            class="mb12"
            v-model:value="cmState.search.key"
            placeholder="输入客户编号/名称/联系人/电话/备注查询"
            enter-button
            style="width:400px"
            @search="getCustomer(1,cmState.limit)"
        />
        <a-table :pagination="false" rowKey="id" :data-source="cmState.list" :columns="[
                {title:'客户编号',dataIndex:'id'},
                {title:'客户名称',dataIndex:'nickname'},
                {title:'客户等级',dataIndex:'level.level'},
                {title:'联系人',dataIndex:'contact_person'},
                {title:'手机',dataIndex:'mobile'},
                {title:'QQ/微信/email',dataIndex:'contact_number'},
                {title:'送货地址',dataIndex:'address'},
                {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
            ]">
            <template #action="{record}">
                <a-button type="link" @click="selectCustomer(record)">选择</a-button>
            </template>
        </a-table>
        <kd-pager :page-data="cmState" :event="getCustomer"></kd-pager>
    </a-modal>
</div>
</template>

<script>
import {ref} from "vue";
import { useCustomer} from '@/models/addons/storage'
export default {
    name: "com-select-customer",
    setup(props,context){
        let show = ref(false)
        let info = ref({})
        let { cmState,getCustomer} = useCustomer()
        getCustomer(1,cmState.limit)

        function selectCustomer(data){
            info.value = data
            context.emit('success',data.id)
            show.value = false
        }

        return { show,info,cmState,getCustomer ,selectCustomer }
    }
}
</script>

<style scoped>

</style>
