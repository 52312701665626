<template>
<div>
    <a-card>
        <template #title>
            <router-link to="/adhibition/storage/outbound_edit">
                <a-button type="primary" v-has="{action:'/adhibition/storage/outbound_edit',plat:0}">
                    <i class="ri-add-line ri-top"></i>添加出库
                </a-button>
            </router-link>
        </template>
        <template #extra>
            <a-input-search
                v-model:value="obState.search.key"
                placeholder="出库单据 / 产品名称 / 规格型号"
                enter-button
                style="width:400px"
                @search="getOutBoundRecord(1,obState.limit)"
            />
        </template>

        <a-table :pagination="false" rowKey="id" :data-source="obState.list" :columns="[
            {title:'单据编号',dataIndex:'id'},
            {title:'单据日期',dataIndex:'document_date'},
            {title:'出库类型',dataIndex:'class',slots:{customRender: 'class'}},
            {title:'图片',dataIndex:'classInfo.images',slots:{customRender: 'images'}},
            {title:'产品名称',dataIndex:'classInfo.name'},
            {title:'规格型号',dataIndex:'classInfo.model',slots:{customRender: 'model'}},
            {title:'所属仓库',dataIndex:'depot.name'},
            {title:'出库数量',dataIndex:'amount'},
            {title:'备注',dataIndex:'remark'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]">
            <template #class="{record}">
                <span v-if="record.class === 1 ">销售出库</span>
                <span v-if="record.class === 2 ">调用出库</span>
                <span v-if="record.class === 3 ">调拔出库</span>
            </template>
            <template #images="{record}">
                <a-image :src=" record.classInfo.images[0]" width="50px"
                         v-if="record.classInfo && record.classInfo.images && record.classInfo.images.length > 0">
                </a-image>
            </template>
            <template #model="{record}">
                <span v-if="record.classInfo && record.classInfo.model">{{record.classInfo.model}}</span>
                <span v-else>--</span>
            </template>
            <template #action="{record}">
                <kd-button title="编辑" icon="ri-edit-line" @click="getOutBoundDetail(record.id)"
                           v-has="{action:'/adhibition/storage/outbound_edit',plat:0}">
                </kd-button>
                <kd-button title="查看凭证"
                           icon="ri-profile-line"
                           type="success"
                           @click="showVoucher(record.voucher)"
                           v-has="{action:'wxapp_storage_outbound_look',plat:0}">
                </kd-button>
                <com-bound-record :id="record.id" :type="2" style="display: inline-block;">
                    <kd-button title="操作记录" icon="ri-file-list-3-line" type="purple"
                               v-has="{action:'wxapp_storage_outbound_record',plat:0}">
                    </kd-button>
                </com-bound-record>
            </template>
        </a-table>
        <kd-pager :page-data="obState" :event="getOutBoundRecord"></kd-pager>
    </a-card>
    <a-modal title="查看凭证"  width="500px" v-model:visible="vState.show" :footer="null" :bodyStyle="{textAlign:'center'}">
        <a-image :src="vState.url" width="400px"></a-image>
    </a-modal>

    <a-modal title="出库记录编辑"  width="800px" v-model:visible="uobState.show" @ok="saveUpdateOutBound">
        <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
            <a-form-item label="出库方式">
                <a-select v-model:value="uobState.form.class" placeholder="选择入库方式" disabled>
                    <a-select-option :value="1">销售出库</a-select-option>
                    <a-select-option :value="2">调用出库</a-select-option>
                    <a-select-option :value="3">调拔出库</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="产品信息">
                <a-space>
                    <a-space v-if="uobState.form.productInfo">
                        <span>{{ uobState.form.productInfo.name}}</span>
                        <div v-if="uobState.form.productInfo.sku && uobState.form.productInfo.sku.length >0">
                            <a-select v-model:value="uobState.form.sku_id" style="width: 200px" placeholder="选择规格">
                                <a-select-option :value="item.id" v-for="(item,index) in uobState.form.productInfo.sku" key="index">
                                    {{ item.model }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <template v-else>
                            <span>--{{ uobState.form.productInfo.model}}</span>
                        </template>
                    </a-space>
                </a-space>
            </a-form-item>
            <a-form-item label="出货仓库">
                <a-select v-model:value="uobState.form.depot_id" placeholder="选择仓库">
                    <a-select-option :value="item.id" v-for="(item,index) in uobState.enterPot" :key="index">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="出库数量">
                <a-input v-model:value="uobState.form.amount" type="number"></a-input>
            </a-form-item>
            <div v-if="uobState.form.class === 1 ">
                <a-form-item label="销售单价">
                    <a-input v-model:value="uobState.form.unit_price" addon-after="元"></a-input>
                </a-form-item>
                <a-form-item label="选择客户">
                    <com-select-customer @success="e=>uobState.form.guest_id = e"></com-select-customer>
                </a-form-item>
                <a-form-item label="销售员">
                    <a-input v-model:value="uobState.form.seller"></a-input>
                </a-form-item>
            </div>

            <div v-if="uobState.form.class === 2 ">
                <a-form-item label="调用人">
                    <a-input v-model:value="uobState.form.caller"></a-input>
                </a-form-item>
                <a-form-item label="物品状态">
                    <a-select v-model:value="uobState.form.loss" placeholder="物品状态">
                        <a-select-option :value="1">完好无损</a-select-option>
                        <a-select-option :value="2">轻微损耗</a-select-option>
                        <a-select-option :value="3">中度损耗</a-select-option>
                        <a-select-option :value="4">严重损耗</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="调用类型">
                    <a-select v-model:value="uobState.form.call_type" style="width: 120px" placeholder="调用类型">
                        <a-select-option :value="1">无需归还</a-select-option>
                        <a-select-option :value="2">需归还</a-select-option>
                        <a-select-option :value="3">已归还</a-select-option>
                    </a-select>
                </a-form-item>
            </div>

            <div v-if="uobState.form.class === 3 ">
                <a-form-item label="收货仓库">
                    <a-select v-model:value="uobState.form.receive_depot_id" placeholder="选择仓库">
                        <a-select-option :value="item.id" v-for="(item,index) in uobState.enterPot" :key="index">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-item>
            </div>

            <a-form-item label="上传凭证">
                <kd-img-select :src="uobState.form.voucher" @success="e=>uobState.form.voucher = e"></kd-img-select>
            </a-form-item>
            <a-form-item label="备注">
                <a-textarea v-model:value="uobState.form.remark" placeholder="备注" :rows="4" />
            </a-form-item>

        </a-form>
    </a-modal>
</div>
</template>

<script>
// import comSelectProduct from './com-select-product.vue'
// import comSelectFarmData from './com-select-farm-data.vue'
import comSelectCustomer from './com-select-customer.vue'
import comBoundRecord from './com-bound-record.vue'
import { useOutBound ,useVoucher,useUpdateOutBound } from '@/models/addons/storage'

export default {
    components:{
        // comSelectProduct,
        // comSelectFarmData,
        comSelectCustomer,
        comBoundRecord
    },
    setup(){
        let { obState,getOutBoundRecord } = useOutBound()
        getOutBoundRecord(1,obState.limit)
        //查看凭证
        let { vState,showVoucher } = useVoucher()
        let { uobState,getOutBoundDetail,saveUpdateOutBound } = useUpdateOutBound(obState,getOutBoundRecord)
        return {
            obState,getOutBoundRecord,
            vState,showVoucher,
            uobState,getOutBoundDetail,saveUpdateOutBound
        }
    }
}
</script>

<style scoped>

</style>
